"use client";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { ChangeEvent, forwardRef, NamedExoticComponent, useId } from "react";

import { InputProps } from "../../props/input-props";
import { ErrorMessage } from "../error-message/error-message";
import { Label } from "../labels/label";
import styles from "./checkbox.module.scss";
import { useCheckboxGroupContext } from "./checkbox-group-context";
const cx = classNames.bind(styles);

type Props = {
  /** The value the checkbox holds. If left undefined, you can use the checked value instead */
  value?: string;
};

export type CheckboxProps = Props & Omit<InputProps, "description" | "size">;

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      className,
      style,
      name,
      label,
      error,
      disabled,
      checked,
      hideLabel,
      value,
      required,
      onChange,
      ...rest
    } = props;

    const errorId = useId();
    const id = useId();
    const context = useCheckboxGroupContext();
    const isRequired = required && !context;

    const isGroupChecked =
      value !== undefined && !!context?.selectedValues?.includes(value);
    const checkedProp = context ? isGroupChecked || !!checked : checked;
    const hasError = !!(error ?? context?.error);

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
      onChange?.(event);
      value !== undefined && context?.toggleValue(value);
    }

    return (
      <>
        <div
          className={cx("design-checkbox-container", className, {
            error: hasError
          })}
          style={style}
        >
          <div className={cx("design-checkbox-square")}>
            <input
              {...rest}
              disabled={context?.disabled || disabled}
              type="checkbox"
              name={context?.name ?? name}
              value={value}
              checked={checkedProp}
              aria-checked={checkedProp}
              aria-label={label as string}
              aria-invalid={hasError}
              aria-errormessage={errorId}
              className={cx("design-checkbox-input")}
              ref={ref}
              id={id}
              onChange={handleOnChange}
            />
            <FontAwesomeIcon
              className={cx("design-checkbox-mark")}
              icon={faCheck}
            />
          </div>
          {!hideLabel && (
            <Label
              htmlFor={id}
              label={label}
              required={isRequired}
              disabled={disabled}
              className={cx("design-checkbox-label")}
            />
          )}
        </div>
        {error && (
          <ErrorMessage className={cx("design-checkbox-error")} id={errorId}>
            {error}
          </ErrorMessage>
        )}
      </>
    );
  }
);

(Checkbox as NamedExoticComponent).displayName = "Checkbox";
