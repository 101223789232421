import classNames from "classnames/bind";

import { InputWrapperProps } from "../../props/input-props";
import { ErrorMessage } from "../error-message/error-message";
import { Label } from "../labels/label";
import styles from "./input-wrapper.module.scss";
const cx = classNames.bind(styles);

export function InputWrapper({
  hideLabel,
  fullWidth,
  error,
  errorId,
  className,
  children,
  ...rest
}: InputWrapperProps) {
  const isArrayOfErrors = Array.isArray(error);

  return (
    <div className={cx("design-input-wrapper", className)}>
      {!hideLabel && <Label {...rest} />}
      <div
        className={cx("design-input-element-wrapper", {
          "full-width": fullWidth
        })}
      >
        {children}
      </div>
      {error &&
        (isArrayOfErrors ? (
          /**The `errorId` is associated with the input field that caused the error.
           * Since there are multiple error messages for this field,
           * This `div` uses the `errorId` to reference the field, allowing assistive technologies
           * to associate all these error messages with the corresponding field. */
          <div id={errorId}>
            {error.map((err, index) => (
              <ErrorMessage
                key={`${errorId}-${index}`}
                id={`${errorId}-${index}`}
              >
                {err}
              </ErrorMessage>
            ))}
          </div>
        ) : (
          <ErrorMessage id={errorId}>{error}</ErrorMessage>
        ))}
    </div>
  );
}
