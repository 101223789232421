"use client";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListboxOption, ListboxOptionProps } from "@headlessui/react";
import classNames from "classnames/bind";
import { ElementType } from "react";

import styles from "./select.module.scss";

const cx = classNames.bind(styles);
type Props<T = unknown, C extends ElementType = "li"> = Omit<
  ListboxOptionProps<C, T>,
  "className"
> & {
  className?: string;
};

export function SelectOption({
  children,
  className,
  disabled,
  ...props
}: Props) {
  return (
    <ListboxOption
      {...props}
      disabled={disabled}
      className={({ selected, focus }) =>
        cx("select-option", { selected }, { focus }, { disabled }, className)
      }
    >
      {({ selected }) => (
        <>
          <FontAwesomeIcon
            aria-hidden={!selected}
            className={cx("selected-checked-icon", { selected })}
            size="xs"
            icon={faCheck}
          />
          {children}
        </>
      )}
    </ListboxOption>
  );
}
