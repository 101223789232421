"use client";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { filterOutChildren, findChildren, useDrag } from "@hdir/utility";
import {
  Dialog as PrimitiveModal,
  DialogPanel as PrimitiveModalPanel,
  DialogTitle as PrimitiveModalTitle,
  Transition,
  TransitionChild
} from "@headlessui/react";
import classNames from "classnames/bind";
import { ComponentProps, PropsWithChildren, ReactNode } from "react";

import { ActionButtons } from "../action-buttons/action-buttons";
import { Button } from "../button/button";
import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./modal.module.scss";

const cx = classNames.bind(styles);

export type MaxWidth = "sm" | "md" | "lg";

export type ModalProps = {
  /**
   * Any error you might want to show to the user
   */
  error?: ReactNode;
  /**
   * Whether or not the modal is open
   */
  isOpen: boolean;
  /**
   * Whether or not the modal is draggable
   */
  isDraggable?: boolean;
  /**
   * Whether or not the overlay should be hidden
   */
  hideOverlay?: boolean;
  /**
   * Whether or not the close button in the top right corner should be hidden
   */
  hideCloseButton?: boolean;
  /**
   * The title of the modal
   */
  title: string | JSX.Element;
  /**
   * Callback for when the close button is clicked
   */
  onCloseClick: () => void;
  /**
   * Max width of modal
   * @default sm
   */
  maxWidth?: MaxWidth;
  /**
   * Whether or not the modal should be full screen
   * Overrides maxWidth
   */
  fullScreen?: boolean;
  /**
   * What the value of the z-index of the modal root is
   * PS.Components with inherit z-indexes (autocompletes, selects, etc) will not work if z-index is applied to modal
   */
  zIndex?: number;
} & Omit<ComponentProps<"div">, "title">;

export function Modal({
  error,
  onCloseClick,
  isOpen,
  title,
  hideOverlay,
  hideCloseButton = false,
  isDraggable,
  children,
  className,
  style,
  maxWidth = "sm",
  fullScreen,
  zIndex = 0,
  ...rest
}: ModalProps) {
  // Fikse og evt fjerne når depricated .notasjon ikke er i bruk lenger.
  // Vet ikke om dette fungerer med begge notasjonene, må Modal.ActionButtons også "finnes"?
  const actionButtons = findChildren(children, ModalActionButtons);
  const body = filterOutChildren(children, ModalActionButtons);

  const { isDragging, translate, dragRef } = useDrag();

  const { translation } = useUiContext();

  return (
    <Transition appear show={isOpen}>
      <PrimitiveModal
        className={cx("modal-root")}
        style={{ "--modal-z-index": zIndex } as React.CSSProperties}
        onClose={() => onCloseClick()}
      >
        <TransitionChild
          enter={cx("enter")}
          enterFrom={cx("overlay-closed")}
          enterTo={cx("overlay-open")}
          leave={cx("leave")}
          leaveFrom={cx("overlay-open")}
          leaveTo={cx("overlay-closed")}
        >
          <div
            className={cx("modal-overlay", { hide: hideOverlay })}
            aria-hidden="true"
          />
        </TransitionChild>

        <div
          className={cx("modal-panel", { draggable: isDraggable })}
          style={{
            transform: `translateX(${translate.x}px) translateY(${translate.y}px)`
          }}
          {...rest}
        >
          <TransitionChild
            enter={cx("enter")}
            enterFrom={cx("modal-closed")}
            enterTo={cx("modal-open")}
            leave={cx("leave")}
            leaveFrom={cx("modal-open")}
            leaveTo={cx("modal-closed")}
          >
            <PrimitiveModalPanel
              className={cx(
                "modal-content",
                [`max-width-${maxWidth}`],
                { fullScreen },
                className
              )}
              style={style}
            >
              {isDraggable && (
                <div
                  className={cx("modal-drag-handle", { dragging: isDragging })}
                  ref={dragRef}
                >
                  ...
                </div>
              )}

              <header className={cx("modal-header")}>
                <PrimitiveModalTitle className={cx("modal-title")}>
                  {title}
                </PrimitiveModalTitle>

                {!hideCloseButton && (
                  <Button
                    variant="icon"
                    className={cx("close-icon-button")}
                    aria-label={translation.modal.closeButton}
                    onClick={onCloseClick}
                  >
                    <FontAwesomeIcon fixedWidth icon={faXmark} />
                  </Button>
                )}
              </header>

              {body}

              {actionButtons && (
                <ActionButtons error={error} className={cx("action-buttons")}>
                  {actionButtons}
                </ActionButtons>
              )}
            </PrimitiveModalPanel>
          </TransitionChild>
        </div>
      </PrimitiveModal>
    </Transition>
  );
}

export function ModalActionButtons({ children }: PropsWithChildren) {
  return <>{children}</>;
}

/**
 * @deprecated Use ModalActionButtons instead
 */
Modal.ActionButtons = ModalActionButtons;
