import classNames from "classnames/bind";

import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./paginator.module.scss";
const cx = classNames.bind(styles);
type EllipsisProps = {
  className?: string;
};

export const Ellipsis = ({ className }: EllipsisProps) => {
  const { translation } = useUiContext();

  return (
    <li className={cx("ellipsis", className)}>
      <span
        className={cx("text")}
        aria-label={translation.paginator.morePagesBetween}
      >
        ...
      </span>
    </li>
  );
};
