export type UiContextTranslation = {
  autocomplete: {
    selectedItems: string;
    clear: string;
    noResults: string;
    typeToSearch: `${string}{minQueryLength}${string}`;
  };
  select: {
    removeItem: string;
  };
  breadcrumbs: {
    ariaLabel: string;
  };
  datepicker: {
    errorMessage: string;
    months: string[];
    nextMonth: string;
    openCalendar: string;
    previousMonth: string;
    selectMonth: string;
    selectYear: string;
  };
  table: {
    expandRow: string;
    paginatorActivated: string;
    paginator: {
      type: string;
      shownRows: `${string}{0}${string}{1}${string}`;
      itemsPerPage: string;
      chooseItemsPerPage: string;
      firstPage: string;
      previousPage: string;
      nextPage: string;
      lastPage: string;
    };
    sortable: string;
  };
  paginator: {
    shownElements: `${string}{0}${string}{1}${string}`;
    navigateTo: `${string}{0}`;
    currentPage: `${string}{0}`;
    morePagesBetween: string;
  };
  modal: {
    closeButton: string;
  };
  general: {
    remove: `${string}{item}`;
  };
  errorMessage: {
    exceedCharLimit: `${string}{inputValueLength}${string}{characterCounterLimit}${string}`;
  };
  loading: string;
};

const en: UiContextTranslation = {
  autocomplete: {
    selectedItems: "Selected items",
    clear: "Clear",
    noResults: "No results found",
    typeToSearch: `Please type at least {minQueryLength} characters to search`
  },
  select: {
    removeItem: "Remove selected item"
  },

  breadcrumbs: {
    ariaLabel: "breadcrumbs"
  },
  datepicker: {
    selectMonth: "month",
    selectYear: "year",
    errorMessage: `The date must be on a valid format to be pasted (dd.mm.yyyy, dd/mm/yyyy, dd-mm-yyyy)`,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    nextMonth: "Next month",
    openCalendar: "Open calendar",
    previousMonth: "Previous month"
  },

  table: {
    expandRow: "Expand row",
    paginatorActivated: "This table has multiple pages.",
    paginator: {
      type: "Pagination",
      shownRows: `Displaying {0} out of {1} rows`,
      itemsPerPage: "Items per page",
      chooseItemsPerPage: "Select the number of items to display per page",
      firstPage: "Navigate to the first page",
      previousPage: "Navigate to the previous page",
      nextPage: "Navigate to the next page",
      lastPage: "Navigate to the last page"
    },
    sortable: "Click the header buttons to sort the table."
  },

  paginator: {
    shownElements: `Displaying {0} out of {1} items`,
    navigateTo: `Navigate to page {0}`,
    currentPage: "Displaying page {0}",
    morePagesBetween: "More pages between"
  },

  modal: {
    closeButton: "Close"
  },
  general: {
    remove: "Remove {item}"
  },
  errorMessage: {
    exceedCharLimit:
      "Max number of characters exceeded. {inputValueLength}/{characterCounterLimit}."
  },
  loading: "Loading..."
};
const nb: UiContextTranslation = {
  autocomplete: {
    selectedItems: "Valgte elementer",
    clear: "Fjern",
    noResults: "Ingen resultater funnet",
    typeToSearch: `Skriv inn minimum {minQueryLength} tegn for å søke`
  },
  select: {
    removeItem: "Fjern valgt element"
  },
  breadcrumbs: {
    ariaLabel: "brødsmuler"
  },
  datepicker: {
    selectMonth: "måned",
    selectYear: "år",
    errorMessage: `Dato må være på gyldig format for å kunne limes inn (dd.mm.yyyy, dd/mm/yyyy, dd-mm-yyyy)`,
    months: [
      "Januar",
      "Februar",
      "Mars",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Desember"
    ],
    nextMonth: "Neste måned",
    openCalendar: "Åpne kalender",
    previousMonth: "Forrige måned"
  },

  table: {
    expandRow: "Ekspander rad",
    paginatorActivated: "Tabellen inneholder flere sider.",
    paginator: {
      type: "Sideteller",
      shownRows: `Viser {0} av {1} rader`,
      itemsPerPage: "Elementer per side",
      chooseItemsPerPage: "Velg antall elementer som vises per side",
      firstPage: "Naviger til første side",
      previousPage: "Naviger til forrige side",
      nextPage: "Naviger til neste side",
      lastPage: "Naviger til siste side"
    },
    sortable: "Klikk på kolonnenavn-knappene for å sortere tabellen."
  },
  paginator: {
    shownElements: `Viser {0} av {1} elementer`,
    navigateTo: `Naviger til side {0}`,
    currentPage: "Viser side {0}",
    morePagesBetween: "Flere sider"
  },
  modal: {
    closeButton: "Lukk"
  },
  general: {
    remove: "Fjern {item}"
  },
  errorMessage: {
    exceedCharLimit:
      "For mange tegn inntastet. {inputValueLength}/{characterCounterLimit}."
  },
  loading: "Laster..."
};
export type UiContextLanguage = "en" | "nb";
export const translation: Record<UiContextLanguage, UiContextTranslation> = {
  en,
  nb
};
export type UiContext = {
  translation: UiContextTranslation;
};
