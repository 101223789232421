import classNames from "classnames/bind";
import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode
} from "react";

import styles from "./card.module.scss";

const cx = classNames.bind(styles);

export type CardProps = HTMLAttributes<HTMLDivElement>;

type CardComponent = ForwardRefExoticComponent<CardProps> & {
  Banner: FC<CardBannerProps>;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cx("card", className)} {...props}>
      {children}
    </div>
  )
) as CardComponent;

Card.displayName = "Card";

export type CardBannerProps = {
  position: "top" | "bottom" | "left" | "right" | "middle-x" | "middle-y";
  children: ReactNode;
};

export function CardBanner({ children, position }: CardBannerProps) {
  return <div className={cx("card-banner", position)}>{children}</div>;
}

// Når dette fjernes kan typen CardComponent fjernes, og Card kan caste as FC<CardProps> i stedet
/**
 * @deprecated Use CardBanner instead
 */
Card.Banner = CardBanner;
