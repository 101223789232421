"use client";
import classNames from "classnames/bind";
import { forwardRef, NamedExoticComponent, useId } from "react";

import { InputProps } from "../../props/input-props";
import { InputWrapper } from "../input-wrapper/input-wrapper";
import styles from "./text-field.module.scss";
const cx = classNames.bind(styles);

type Props = {
  /** Types a text field supports. */
  type?: "email" | "password" | "url" | "text" | "tel" | "number" | "date";
  value?: string;
};

export type TextFieldProps = Props & InputProps;

// eslint-disable-next-line react/display-name
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      name,
      error,
      className,
      hideArrows = false,
      description,
      fullWidth,
      hideLabel,
      id,
      disabled,
      required,
      type = "text",
      infoText,
      ...rest
    },
    ref
  ) => {
    const defaultId = useId();
    const fieldId = id ?? defaultId;
    const errorId = useId();

    if (type === "date") {
      // eslint-disable-next-line no-console
      console.warn(
        'Deprecation warning: input type="date" er ikke anbefalt å bruke. HDIR date-picker er under utvikling. Derfor vil det å benytte input type="date" medføre vedlikehold i fremtiden'
      );
    }

    return (
      <InputWrapper
        className={className}
        label={label}
        disabled={disabled}
        fullWidth={fullWidth}
        description={description}
        htmlFor={fieldId}
        hideLabel={hideLabel}
        error={error}
        errorId={errorId}
        required={required}
        infoText={infoText}
      >
        <input
          className={cx("design-text-field-input", {
            "hide-arrows": hideArrows,
            error
          })}
          type={type}
          disabled={disabled}
          ref={ref}
          name={name}
          onKeyDown={(e) =>
            hideArrows &&
            ["ArrowUp", "ArrowDown"].includes(e.key) &&
            e.preventDefault()
          }
          id={fieldId}
          aria-invalid={!!error}
          aria-errormessage={error ? errorId : undefined}
          {...rest}
        />
      </InputWrapper>
    );
  }
);

(TextField as NamedExoticComponent).displayName = "TextField";
