import { HeaderGroup } from "@tanstack/react-table";

import { useUiContext } from "../../ui-context/use-ui-context";

type Props<T> = {
  totalRowsCount: number;
  pageSize: number;
  hideFooter: boolean | undefined;
  headerGroups: HeaderGroup<T>[];
  caption?: string;
  title?: string;
};

export function SrOnlyTableCaption<T>({
  totalRowsCount,
  pageSize,
  hideFooter,
  headerGroups,
  caption,
  title
}: Props<T>) {
  const { translation } = useUiContext();

  const isPaginatorActivated = totalRowsCount > pageSize && !hideFooter;
  const isSortable = headerGroups.some((headerGroup) =>
    headerGroup.headers.some(
      (header) => header.column.columnDef.enableSorting !== false
    )
  );
  const customCaptionText =
    (caption ?? title ?? "") + (caption || title ? ". " : "");
  const paginatorText = isPaginatorActivated
    ? translation.table.paginatorActivated
    : "";
  const sortableText = isSortable ? translation.table.sortable : "";
  const captionText = `${customCaptionText}${paginatorText} ${sortableText}`;

  return <caption className="sr-only">{captionText}</caption>;
}
