"use client";
import classnames from "classnames/bind";
import React from "react";
import { useDateSegment } from "react-aria";
import { DateFieldState, DateSegment } from "react-stately";

import styles from "./date-picker.module.scss";

const cx = classnames.bind(styles);
type SegmentProps = {
  segment: DateSegment;
  state: DateFieldState;
};
// Format the year segment with leading zeros
const formatLeadingZeros = (text: string, segmentType: string): string => {
  if (segmentType === "year") {
    return text.padStart(4, "0");
  }
  return text;
};
function Segment({ segment, state }: SegmentProps) {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div {...segmentProps} ref={ref} className={cx("segment")}>
      {segment.isPlaceholder
        ? segment.placeholder
        : formatLeadingZeros(segment.text, segment.type)}
    </div>
  );
}

export default Segment;
