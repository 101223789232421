import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames/bind";
import { PropsWithChildren } from "react";

import { useUiContext } from "../../ui-context/use-ui-context";
import { isSelected, Value } from "../is-selected";
import styles from "./autocomplete-items-list.module.scss";
const cx = classnames.bind(styles);

type ListProps<T> = ItemsProps<T> & {
  multiselect?: boolean;
};

type ItemsProps<T> = {
  activeIndex?: number;
  /** The active search query */
  query: string;
  value: Value<T>;
  items: T[];
  noResultsMessage?: string;
  isRemovable?: (option: T) => boolean;
  getDisplayName: (option: T) => string;
  getItemId: (option: T) => string;
  onSelect: (option: T) => void;
  minQueryLength: number;
  isLoading?: boolean;
};

export function AutocompleteItemsList<T>(props: ListProps<T>) {
  return (
    <ul
      className={cx("design-items-list")}
      role="listbox"
      id="autocomplete-list"
      tabIndex={-1}
      aria-multiselectable={props.multiselect ? "true" : "false"}
      aria-activedescendant={String(props.activeIndex ?? "")}
    >
      <AutocompleteItems {...props} />
    </ul>
  );
}

export function AutocompleteItems<T>(props: ItemsProps<T>) {
  const {
    activeIndex,
    items,
    getItemId,
    onSelect,
    minQueryLength,
    query,
    isRemovable = () => true,
    isLoading
  } = props;

  const { translation } = useUiContext();

  const noResultsMessage =
    props.noResultsMessage ?? translation.autocomplete.noResults;

  const typeToSearchMessage = translation.autocomplete.typeToSearch.replace(
    "{minQueryLength}",
    `${minQueryLength}`
  );

  const hasNoResults = items.length === 0;

  if (query.length < minQueryLength)
    return <MessageItem>{typeToSearchMessage}</MessageItem>;
  if (isLoading) return <MessageItem>{translation.loading}</MessageItem>;
  if (hasNoResults) return <MessageItem>{noResultsMessage}</MessageItem>;

  function handleOnSelect(item: T) {
    if (isRemovable(item)) onSelect(item);
  }

  return items.map((item, index) => (
    <li key={getItemId(item)}>
      <option
        id={getItemId(item)}
        className={cx("design-item", "design-item-selectable", {
          active: activeIndex === index
        })}
        disabled={!isRemovable(item)}
        aria-selected={isSelected(props.value, item, getItemId)}
        onClick={() => handleOnSelect(item)}
      >
        <FontAwesomeIcon
          className={cx("design-item-selected")}
          size="xs"
          icon={faCheck}
        />
        {props.getDisplayName(item)}
      </option>
    </li>
  ));
}

const MessageItem = ({ children }: PropsWithChildren) => {
  return <li className={cx("design-item")}>{children}</li>;
};
