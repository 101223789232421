"use client";

import { filterOutChildren, findChildren } from "@hdir/utility";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import classNames from "classnames/bind";
import { PropsWithChildren } from "react";

import styles from "./tooltip.module.scss";
import { TooltipProps } from "./tooltip-props";
const cx = classNames.bind(styles);

export function Tooltip({
  children,
  isDisabled,
  isOpen,
  isDefaultOpen,
  showDelay,
  position = "top",
  align = "center",
  onOpenChange,
  className,
  ...rest
}: TooltipProps) {
  const content = findChildren(children, TooltipContent);
  const trigger = filterOutChildren(children, TooltipContent);

  return (
    <RadixTooltip.Root
      open={isOpen}
      delayDuration={showDelay}
      defaultOpen={isDefaultOpen}
      onOpenChange={onOpenChange}
    >
      <RadixTooltip.Trigger asChild>
        <div className={cx("design-tooltip-content-trigger")}>{trigger}</div>
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={cx(
            "design-tooltip-content",
            { disabled: isDisabled },
            className
          )}
          side={position}
          align={align}
          {...rest}
        >
          {content}
          <RadixTooltip.Arrow
            className={cx("design-tooltip-arrow")}
            width={11}
            height={5}
          />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}

export const TooltipProvider = RadixTooltip.TooltipProvider;

export function TooltipContent({ children }: PropsWithChildren) {
  return <>{children}</>;
}

/**
 * @deprecated Use TooltipContent instead
 */
Tooltip.Content = TooltipContent;
