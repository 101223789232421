"use client";

import { faCircleInfo as faCircleInfoLight } from "@fortawesome/pro-light-svg-icons";
import { faCircleInfo as faCircleInfoRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { useState } from "react";

import { LabelProps } from "../../props/input-props";
import { Tooltip, TooltipContent } from "../../tooltip/tooltip";
import styles from "./labels.module.scss";
const cx = classNames.bind(styles);

export function Label({
  label,
  description,
  disabled,
  htmlFor,
  className,
  infoText,
  required
}: LabelProps) {
  const [isOpen, setIsOpen] = useState(false);
  const icon = isOpen ? faCircleInfoRegular : faCircleInfoLight;
  return (
    <label
      className={cx("design-label", className, { disabled })}
      htmlFor={htmlFor}
    >
      <div className={cx("design-label-heading")}>
        <span>
          {label}
          {required && <span className={cx({ required })}> *</span>}
        </span>
        {infoText && (
          <Tooltip isOpen={isOpen} onOpenChange={setIsOpen}>
            <TooltipContent>{infoText}</TooltipContent>
            <FontAwesomeIcon
              className={cx("design-label-infoIcon")}
              icon={icon}
            />
          </Tooltip>
        )}
      </div>
      {description && (
        <span className={cx("design-label-description")}>{description}</span>
      )}
    </label>
  );
}
