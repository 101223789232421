"use client";
import { OnChangeFn, SortingState } from "@tanstack/react-table";
import { createContext, ReactNode, useMemo, useState } from "react";

export type SortingContextProviderProps = {
  children: ReactNode;
};
export const sortingContext = createContext<SortingContext | undefined>(
  undefined
);
export type SortingContext = {
  sortingState: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  sortingOrder: SortOrder | undefined;
  sortedColumnId: string | undefined;
};
export enum SortOrder {
  Asc = "asc",
  Desc = "desc"
}
export function SortingContextProvider(props: SortingContextProviderProps) {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const sortingOrder = useMemo(() => {
    if (sortingState.length === 0) {
      return undefined;
    }
    return sortingState[0].desc ? SortOrder.Desc : SortOrder.Asc;
  }, [sortingState]);

  const values = useMemo(
    () => ({
      sortingState,
      onSortingChange: setSortingState,
      sortingOrder,
      sortedColumnId: sortingState.length === 0 ? undefined : sortingState[0].id
    }),
    [sortingOrder, sortingState]
  );

  return (
    <sortingContext.Provider value={values}>
      {props.children}
    </sortingContext.Provider>
  );
}
