"use client";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import classNames from "classnames/bind";
import { ReactNode, useId } from "react";

import { Button } from "../button/button";
import styles from "./accordion.module.scss";

const cx = classNames.bind(styles);

export type AccordionSingleProps = {
  /**
   * Controls whether multiple AccordionItem's can be expanded at once, or if one close by the next one opening.
   *
   * Optional; defaults to multiple.
   *
   */
  type: "single";
  /**
   * Controls which AccordionItem(s) is expanded by default. Defaultvalues type is either string or stringarray depending on "single or multiple" type accordion. Requires that the user set's the value prop for the AccordionItem.
   *
   * Optional, undefined by default.
   */
  defaultValue?: string;
  value?: string;
  className?: string;
  children: ReactNode;
  onValueChange?: (value: string) => void;
  /**
   * Determines whether AccordionItems can be expanded by clicking on the card itself or using a custom button within the card
   *
   * Optional; defaults to undefined, being card-expansion.
   *
   */
  customTrigger?: {
    closedText: string;
    expandedText: string;
    variant:
      | "primary"
      | "secondary"
      | "tertiary"
      | "icon"
      | "icon-filled"
      | "card";
  };
};

export type AccordionMultipleProps = {
  type?: "multiple";
  defaultValue?: string[];
  value?: string[];
  className?: string;
  children: ReactNode;
  onValueChange?: (value: string[]) => void;
};

export type AccordionProps = AccordionSingleProps | AccordionMultipleProps;

export function Accordion({
  children,
  className,
  type,
  defaultValue,
  onValueChange,
  value
}: AccordionProps) {
  if (type === "single") {
    return (
      <AccordionPrimitive.Root
        className={className}
        type="single"
        defaultValue={defaultValue}
        value={value}
        onValueChange={onValueChange}
        collapsible
      >
        {children}
      </AccordionPrimitive.Root>
    );
  }

  return (
    <AccordionPrimitive.Root
      className={className}
      type="multiple"
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
    >
      {children}
    </AccordionPrimitive.Root>
  );
}

export type AccordionItemProps = {
  /**
   * A unique value for the item that can be used to determine which item is expanded by default.
   *
   *  Optional; defaults to a random id.
   */
  value?: string;
  className?: string;
  children: ReactNode;
};

export function AccordionItem({
  className,
  value,
  children
}: AccordionItemProps) {
  const id = useId();

  return (
    <AccordionPrimitive.Item
      value={value ?? id}
      className={cx("accordion-item", className)}
    >
      {children}
    </AccordionPrimitive.Item>
  );
}

export type AccordionTriggerProps = {
  className?: string;
  children: ReactNode;
  /**
   * Controls whether AccordionItem's can be expanded by pressing the card or a custom button inside the card.
   *
   * Optional; defaults to undefined, being card-expansion.
   *
   */
  customTrigger?: {
    closedText: string;
    expandedText: string;
    variant:
      | "primary"
      | "secondary"
      | "tertiary"
      | "icon"
      | "icon-filled"
      | "card";
  };
};

export function AccordionTrigger({
  className,
  children,
  customTrigger
}: AccordionTriggerProps) {
  return (
    <AccordionPrimitive.Header className={cx("accordion-header", className)}>
      {customTrigger ? (
        <div className={cx("accordion-trigger-container")}>
          {children}
          <AccordionPrimitive.Trigger asChild>
            <Button
              className={cx("accordion-trigger-button")}
              variant={customTrigger.variant}
            >
              <span className={cx("accordion-trigger-button-closed")}>
                {customTrigger.closedText}
              </span>
              <span className={cx("accordion-trigger-button-expanded")}>
                {customTrigger.expandedText}
              </span>
              <FontAwesomeIcon
                className={cx("accordion-trigger-icon")}
                icon={faAngleDown}
              />
            </Button>
          </AccordionPrimitive.Trigger>
        </div>
      ) : (
        <AccordionPrimitive.Trigger className={cx("accordion-trigger")}>
          {children}
          <FontAwesomeIcon
            className={cx("accordion-trigger-icon")}
            icon={faAngleDown}
          />
        </AccordionPrimitive.Trigger>
      )}
    </AccordionPrimitive.Header>
  );
}

export type AccordionContentProps = {
  className?: string;
  children: ReactNode;
};

export function AccordionContent({
  className,
  children
}: AccordionContentProps) {
  return (
    <AccordionPrimitive.Content className={cx("accordion-content")}>
      <div className={cx("accordion-content-container", className)}>
        {children}
      </div>
    </AccordionPrimitive.Content>
  );
}

/**
 * @deprecated Use AccordionItem instead
 */
Accordion.Item = AccordionItem;
/**
 * @deprecated Use AccordionTrigger instead
 */
Accordion.Trigger = AccordionTrigger;
/**
 * @deprecated Use AccordionContent instead
 */
Accordion.Content = AccordionContent;
