"use client";

import classNames from "classnames/bind";

import { PaginationArrowButtons } from "../../paginator/pagination-arrow-buttons";
import { useUiContext } from "../../ui-context/use-ui-context";
import { PaginationItem } from "../table-props";
import { PaginatorPageSelect } from "./paginator-page-select";
import styles from "./table-paginator.module.scss";
const cx = classNames.bind(styles);

function getPaginatorModel(
  itemsCount: number,
  itemsPerPage: number,
  activePage: number
) {
  const pages = itemsPerPage ? Math.ceil(itemsCount / itemsPerPage) : 1;
  const startIndex = activePage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, itemsCount);
  return { pages, startIndex, endIndex };
}

export type Props = {
  paginationItems: PaginationItem[];
  itemsCount: number;
  itemsPerPage: number;
  activePage: number;
  tableId: string;
  seperatedFromTable?: boolean;
  onPageClick: (pageIndex: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
};

export function TablePaginator({
  paginationItems,
  itemsPerPage,
  itemsCount,
  activePage,
  tableId,
  seperatedFromTable = true,
  onPageClick,
  onItemsPerPageChange
}: Props) {
  const { translation } = useUiContext();
  const { pages, startIndex, endIndex } = getPaginatorModel(
    itemsCount,
    itemsPerPage,
    activePage
  );

  return (
    <nav
      className={
        seperatedFromTable
          ? cx("design-paginator-rounded")
          : cx("design-paginator")
      }
      aria-label={translation.table.paginator.type}
      aria-controls={tableId}
      role="navigation"
      tabIndex={0}
    >
      <span aria-live="polite" aria-atomic="true">
        {translation.table.paginator.shownRows
          .replace("{0}", `${startIndex + 1}-${endIndex}`)
          .replace("{1}", `${itemsCount}`)}
      </span>

      <PaginationArrowButtons
        activePage={activePage}
        pages={pages}
        className={cx("paginator-buttons")}
        onPageClick={onPageClick}
        tablePaginator
      />
      <PaginatorPageSelect
        paginationItems={paginationItems}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
      />
    </nav>
  );
}
