"use client";

import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames/bind";

import { ToggleGroupItemProps } from "./toggle-group-props";
import styles from "./toggle-item.module.scss";
const cx = classNames.bind(styles);

export function ToggleGroupItem({
  className,
  children,
  ...rest
}: ToggleGroupItemProps) {
  return (
    <RadixToggleGroup.Item
      className={cx("design-toggle-item", className)}
      {...rest}
    >
      {children}
    </RadixToggleGroup.Item>
  );
}
