"use client";
import classnames from "classnames/bind";
import React from "react";
import { AriaCalendarCellProps, useCalendarCell } from "react-aria";
import { CalendarState, RangeCalendarState } from "react-stately";

import styles from "./date-picker.module.scss";
const cx = classnames.bind(styles);

type Props = {
  state: CalendarState | RangeCalendarState;
  date: AriaCalendarCellProps["date"];
  closeModal: () => void;
};

export function CalendarCell({ state, date, closeModal }: Props) {
  const ref = React.useRef(null);

  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    formattedDate
  } = useCalendarCell({ date }, state, ref);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      closeModal();
    }
  };

  return (
    <td {...cellProps}>
      {/* Using <div> to preserve accessibility for the calendar in terms of tabbing from a day to the top menu of the calendar.
        Accessibility is handled by aria */}
      <div // NOSONAR
        {...buttonProps}
        onClick={closeModal}
        onKeyDown={handleKeyPress}
        ref={ref}
        className={cx("cell", {
          selected: isSelected,
          isOutsideVisibleRange: isOutsideVisibleRange
        })}
      >
        {formattedDate}
      </div>
    </td>
  );
}
