import { useContext } from "react";

import { translation, UiContext } from "./ui-context";
import { uiContext } from "./ui-context-provider";

export function useUiContext(): UiContext {
  const context = useContext(uiContext);

  return context ?? { translation: translation.nb };
}
