"use client";
import classnames from "classnames/bind";
import { CalendarState } from "react-stately";

import { Autocomplete } from "../autocomplete/autocomplete";
import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./date-picker.module.scss";

const cx = classnames.bind(styles);

type Props = {
  state: CalendarState;
  futureYears?: number;
};

export function YearDropdown({ state, futureYears = 3 }: Props) {
  const { translation } = useUiContext();

  const today = new Date();
  const currentYear = today.getFullYear();
  const minYear = 1900;
  const maxFutureYear = 2100;

  const yearsFromCurrentDateTo1900: number[] = Array.from(
    { length: currentYear + futureYears - minYear + 1 },
    (_, i) => currentYear + futureYears - i
  );

  const futureYearsBeyondCurrentDate: number[] = Array.from(
    { length: maxFutureYear - (currentYear + futureYears) },
    (_, i) => currentYear + futureYears + 1 + i
  );

  const allYears = [
    ...yearsFromCurrentDateTo1900,
    ...futureYearsBeyondCurrentDate
  ];

  const onChange = (year: number | null) => {
    const updatedYear = year ?? undefined;
    const updatedDate = state.focusedDate.set({
      year: updatedYear
    });
    state.setFocusedDate(updatedDate);
  };
  return (
    <Autocomplete
      name={translation.datepicker.selectYear}
      onChange={onChange}
      defaultValue={state.focusedDate.year}
      label={
        <span className="sr-only">{translation.datepicker.selectYear}</span>
      }
      className={cx("select_year")}
      items={allYears}
      getDisplayName={(item: number) => item.toString()}
    />
  );
}
