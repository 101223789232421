"use client";
import classnames from "classnames/bind";
import { CalendarState } from "react-stately";

import { Select } from "../form-elements/select/select";
import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./date-picker.module.scss";

const cx = classnames.bind(styles);
type MonthItem = {
  monthDisplayString: string;
  value: number;
};

type Props = {
  state: CalendarState;
};
export function MonthDropdown({ state }: Props) {
  const { translation } = useUiContext();

  const numMonths = state.focusedDate.calendar.getMonthsInYear(
    state.focusedDate
  );

  const months: MonthItem[] = Array.from({ length: numMonths }, (_, i) => ({
    monthDisplayString: translation.datepicker.months[i],
    value: i + 1
  }));

  const onChange = (e: MonthItem | null) => {
    const value = Number(e?.value);
    const date = state.focusedDate.set({ month: value });
    state.setFocusedDate(date);
  };
  return (
    <Select
      onChange={onChange}
      getItemId={(item) => item.value}
      value={months[state.focusedDate.month - 1]}
      className={cx("select_month")}
      name={translation.datepicker.selectMonth}
      label={
        <span className="sr-only"> {translation.datepicker.selectMonth}</span>
      }
      getDisplayName={(month: MonthItem) => month.monthDisplayString}
    >
      {months.map((month) => (
        <Select.Option key={month.value} value={month}>
          {month.monthDisplayString}
        </Select.Option>
      ))}
    </Select>
  );
}
