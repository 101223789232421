"use client";
import { getWeeksInMonth } from "@internationalized/date";
import classnames from "classnames/bind";
import { AriaCalendarGridProps, useCalendarGrid, useLocale } from "react-aria";
import { CalendarState, RangeCalendarState } from "react-stately";

import { CalendarCell } from "./calendar-cell";
import styles from "./date-picker.module.scss";
const cx = classnames.bind(styles);

type Props = {
  state: CalendarState | RangeCalendarState;
  closeModal: () => void;
} & AriaCalendarGridProps;

export function CalendarGrid({
  state,
  endDate,
  startDate,
  weekdayStyle,
  closeModal
}: Props) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    { endDate, startDate, weekdayStyle },
    state
  );
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  return (
    <table {...gridProps} className={cx("table")}>
      <thead {...headerProps} className={cx("text_center")}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={`${day}-${index}`}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array.from({ length: weeksInMonth }).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex).map((date) => {
              if (!date) return null;

              const dateKey = date.toString();
              return (
                <CalendarCell
                  key={dateKey}
                  state={state}
                  date={date}
                  closeModal={closeModal}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
