import { SizeProp } from "@fortawesome/fontawesome-svg-core"; // Import SizeProp
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { HTMLAttributes } from "react";

import styles from "./spinner.module.scss";
const cx = classNames.bind(styles);

type Props = {
  size?: "small" | "medium" | "large";
  variant?: "normal" | "inverted";
} & HTMLAttributes<HTMLDivElement>;

const sizeMap: Record<"small" | "medium" | "large", SizeProp> = {
  small: "1x",
  medium: "2x",
  large: "3x"
};

export const Spinner = ({
  className,
  size = "medium",
  variant = "normal",
  ...rest
}: Props) => {
  return (
    <div
      className={cx("design-spinner-container", className)}
      aria-live="polite"
      aria-label="Loading"
      {...rest}
    >
      <FontAwesomeIcon
        icon={faSpinner}
        className={cx("design-spinner-icon", variant)}
        spin
        size={sizeMap[size]}
      />
    </div>
  );
};
