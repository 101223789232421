import classNames from "classnames/bind";
import {
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { Row, TableProps } from "../table-props";
import styles from "./table-expandable-row.module.scss";
const cx = classNames.bind(styles);

function getIsTableRows(element?: ReactNode) {
  return Children.toArray(element).some((e) => {
    const childEL = e as ReactElement;
    const children = Children.toArray(childEL.props.children);

    const isTR = isValidElement(childEL) && childEL.type === "tr";
    const isChildrenTR = children.some(
      (a) => isValidElement(a) && a.type === "tr"
    );
    return isTR || isChildrenTR;
  });
}

type Props<T> = Pick<TableProps<T>, "renderExpandable"> & {
  row: Row<T>;
};

// eslint-disable-next-line max-statements
export function TableExpandableRow<T>({ row, renderExpandable }: Props<T>) {
  const isExpanded = row.getIsExpanded();
  const expandableElement = useMemo(
    () => renderExpandable?.(row) ?? <></>,
    [renderExpandable, row]
  );
  const isTableRows = getIsTableRows(expandableElement);

  const [height, setHeight] = useState<number | undefined>(
    isExpanded ? undefined : 0
  );
  const [isHidden, setIsHidden] = useState(true);

  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentHeigth = contentRef.current?.getBoundingClientRect().height;
    const newHeight = isExpanded ? contentHeigth : 0;
    setHeight(newHeight);
  }, [isExpanded, expandableElement]);

  /**
   * Need to delay setting of visibility hidden, until heigth is 0;
   */
  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = setHiddenWhenZeroHeight(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  function setHiddenWhenZeroHeight(node: HTMLDivElement) {
    const resizeObserver = new ResizeObserver(() => {
      const contentHeigth = node?.getBoundingClientRect().height;
      setIsHidden(contentHeigth === 0);
    });
    resizeObserver.observe(node);
    return resizeObserver;
  }

  if (isTableRows) {
    return isExpanded ? expandableElement : <></>;
  }

  return (
    <tr
      className={cx("expandable-row")}
      id={`row-expandable-${row.id}`}
      aria-hidden={!isExpanded}
      style={{ display: !isExpanded && isHidden ? "none" : undefined }}
    >
      <td
        className={cx("design-table-expand-cell")}
        colSpan={row.getVisibleCells().length}
      >
        <div
          className={cx("expandable")}
          style={{ height, visibility: isHidden ? "hidden" : "visible" }}
          ref={containerRef}
        >
          <div ref={contentRef}>{expandableElement}</div>
        </div>
      </td>
    </tr>
  );
}
