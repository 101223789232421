import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import { Button } from "../button/button";
import { useUiContext } from "../ui-context/use-ui-context";
import { ActivePageIndicator } from "./active-page-indicator";
import { PageNumberButtons } from "./page-number-buttons";
import styles from "./paginator.module.scss";
const cx = classNames.bind(styles);

export type Props = {
  aktivSide: number;
  antallSider: number;
  className: string;
  onPageClick: (pageIndex: number) => void;
  tablePaginator?: boolean;
};

export function PaginationButtons({
  aktivSide,
  antallSider,
  className,
  tablePaginator = false,

  onPageClick
}: Props) {
  const { translation } = useUiContext();

  return (
    <>
      <span className="sr-only" aria-live="polite" aria-atomic>
        {translation.paginator.currentPage.replace("{0}", `${aktivSide + 1}`)}
      </span>
      <ul className={cx(className)}>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.firstPage}
            className={
              tablePaginator
                ? cx(
                    "design-paginator-arrow-button",
                    "paginator-button-small-container"
                  )
                : cx(
                    "design-paginator-button",
                    "paginator-button-small-container"
                  )
            }
            disabled={aktivSide === 0}
            onClick={() => onPageClick(0)}
          >
            <FontAwesomeIcon icon={faAnglesLeft} aria-hidden />
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.previousPage}
            className={
              tablePaginator
                ? cx("design-paginator-arrow-button")
                : cx("design-paginator-button")
            }
            disabled={aktivSide === 0}
            onClick={() => onPageClick(aktivSide - 1)}
          >
            <FontAwesomeIcon fixedWidth icon={faAngleLeft} aria-hidden />
          </Button>
        </li>
        <div className={cx("paginator-button-small-container")}>
          <ActivePageIndicator aktivSide={aktivSide} />
        </div>
        <div className={cx("paginator-button-medium-container")}>
          <PageNumberButtons
            aktivSide={aktivSide}
            antallSider={antallSider}
            onPageClick={onPageClick}
          />
        </div>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.nextPage}
            className={
              tablePaginator
                ? cx("design-paginator-arrow-button")
                : cx("design-paginator-button")
            }
            disabled={aktivSide === antallSider - 1}
            onClick={() => onPageClick(aktivSide + 1)}
          >
            <FontAwesomeIcon fixedWidth icon={faAngleRight} aria-hidden />
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.lastPage}
            className={
              tablePaginator
                ? cx(
                    "design-paginator-arrow-button",
                    "paginator-button-small-container"
                  )
                : cx(
                    "design-paginator-button",
                    "paginator-button-small-container"
                  )
            }
            disabled={aktivSide === antallSider - 1}
            onClick={() => onPageClick(antallSider - 1)}
          >
            <FontAwesomeIcon icon={faAnglesRight} aria-hidden />
          </Button>
        </li>
      </ul>
    </>
  );
}
