"use client";

import * as TabNav from "@radix-ui/react-navigation-menu";
import classNames from "classnames/bind";
import { Children, ReactElement, ReactNode } from "react";

import styles from "../tab/tab.module.scss";

const cx = classNames.bind(styles);
export type NavigationTabProps = {
  children: ReactNode;
  className?: string;
  /**
   * The href of the current page.
   */
  hrefActiveTab: string;
  position?: "left" | "center" | "right";
};

export function NavigationTab({
  children,
  hrefActiveTab,
  className,
  position = "left"
}: NavigationTabProps) {
  return (
    <TabNav.Root className={className} defaultValue={hrefActiveTab}>
      <TabNav.List className={cx("tab-list", position)}>
        {Children.map(children, (child) => {
          const href =
            (child as ReactElement).props.href ??
            (child as ReactElement).props.to;
          return (
            <TabNav.Link
              href={href}
              asChild
              key={href}
              className={cx("tab-trigger")}
              data-state={href === hrefActiveTab ? "active" : "inactive"}
            >
              {child}
            </TabNav.Link>
          );
        })}
      </TabNav.List>
    </TabNav.Root>
  );
}
