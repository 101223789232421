"use client";

import * as Popover from "@radix-ui/react-popover";
import { forwardRef, useId } from "react";

import { SelectedItems } from "../autocomplete/selected-items/selected-items";
import { useMergedRef } from "../autocomplete/use-merged-ref";
import { InputWrapper } from "../form-elements/input-wrapper/input-wrapper";
import { AutocompleteInput } from "./autocomplete-input/autocomplete-input";
import { AutocompleteItemsList } from "./autocomplete-options-list/autocomplete-items-list";
import { ExtendedBaseAutocompleteProps } from "./autocomplete-props";
import { useBaseAutocomplete } from "./use-base-autocomplete";

function BaseAutocomplete<T>(props: ExtendedBaseAutocompleteProps<T>) {
  const {
    multiselect,
    label,
    required,
    error,
    disabled,
    isRemovable,
    description,
    hideLabel,
    className,
    name,
    noResultsMessage,
    ref,
    fullWidth = true,
    minQueryLength = 0
  } = props;
  const {
    inputRef,
    isOpen,
    setIsOpen,
    value,
    activeDescendant,
    activeIndex,
    onSelectedValue,
    onRemoveValue,
    query,
    onQueryChange,
    getDisplayName,
    getItemId,
    filteredItems,
    isLoading
  } = useBaseAutocomplete(props);

  const mergedInputRef = useMergedRef(inputRef, ref);

  const fieldId = useId();
  const errorId = useId();

  /* When using "multiselect", the selected values will be shown as chips unlike single select where
   * the selected value is the text inside the input.
   * Therefore we need to link the input to the list of chips with an `aria-describedby`.
   */
  const describedById = multiselect ? `multiselect-values-${fieldId}` : "";
  return (
    <InputWrapper
      label={label}
      fullWidth={fullWidth}
      description={description}
      disabled={disabled}
      htmlFor={fieldId}
      hideLabel={hideLabel}
      error={error}
      errorId={errorId}
      className={className}
      required={required}
    >
      <div style={{ width: "100%" }}>
        {multiselect && Array.isArray(value) && !props.hideChips && (
          <SelectedItems
            id={describedById}
            disabled={disabled}
            selectedItems={value}
            getDisplayName={getDisplayName}
            getItemId={getItemId}
            orderChipsAlphabetical={props.orderChipsAlphabetical}
            onRemove={onRemoveValue}
          />
        )}
        <Popover.Root
          open={isOpen}
          onOpenChange={(open) => setIsOpen(open && !disabled)}
        >
          <Popover.Trigger asChild>
            {/** Popover needs something to attach to, and inputRef is already used */}
            <div
              data-test="trigger"
              aria-controls="autocomplete-list"
              aria-expanded={isOpen}
              aria-haspopup={isOpen}
            >
              <AutocompleteInput
                isOpen={isOpen}
                id={fieldId}
                disabled={disabled}
                query={query}
                aria-label={props["aria-label"]}
                label={label}
                activeDescendant={activeDescendant}
                ref={mergedInputRef}
                onQueryChange={onQueryChange}
                name={name}
                describedBy={describedById}
                error={error}
                errorId={errorId}
              />
            </div>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              align="start"
              //We don't want the popover to be read out to the screen reader
              role="presentational"
              style={{ width: "var(--radix-popover-trigger-width)" }}
              onOpenAutoFocus={(event) => event.preventDefault()}
              onEscapeKeyDown={() => setIsOpen(false)}
            >
              <AutocompleteItemsList
                activeIndex={activeIndex}
                minQueryLength={minQueryLength}
                multiselect={multiselect}
                value={value}
                query={query}
                noResultsMessage={noResultsMessage}
                items={filteredItems}
                isRemovable={isRemovable}
                getDisplayName={getDisplayName}
                getItemId={getItemId}
                onSelect={onSelectedValue}
                isLoading={isLoading}
              />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </InputWrapper>
  );
}

export default forwardRef(BaseAutocomplete) as <T>(
  props: ExtendedBaseAutocompleteProps<T>
) => ReturnType<typeof BaseAutocomplete>;
