import { assertArray, getDifference, usePrevious } from "@hdir/utility";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { useFieldValue } from "../form-elements/use-field-value";
import { ExtendedBaseAutocompleteProps } from "./autocomplete-props";
import { isSelected } from "./is-selected";
import { useKeyboardSelect } from "./use-keyboard-select";

type CommonProps<T> = {
  valueFromProps: T | T[] | null | undefined;
  setQuery: (query: string) => void;
  getDisplayName: (value: T) => string;
};

type UseSetControlledQueryProps<T> =
  | (CommonProps<T> & {
      multiselect: true;
    })
  | (CommonProps<T> & {
      multiselect?: false;
      setFilteredItems: (defaultValue: T[]) => void;
    });

const useSetControlledValues = <T>({
  valueFromProps,
  setQuery,
  getDisplayName,
  multiselect = false
}: UseSetControlledQueryProps<T>) => {
  const displayNameString =
    valueFromProps && !Array.isArray(valueFromProps)
      ? getDisplayName(valueFromProps)
      : "";

  useEffect(() => {
    if (valueFromProps === undefined) return;

    if (valueFromProps === null) {
      setQuery("");
    } else if (!multiselect) {
      setQuery(displayNameString);
    }
  }, [displayNameString, multiselect, setQuery, valueFromProps]);
};

const invalidIdCharacters = /[^\w-]/g;

const stripInvalidIdCharacters = (str: string): string =>
  str.replaceAll(invalidIdCharacters, "");

// eslint-disable-next-line max-statements
export const useBaseAutocomplete = <T>({
  value: valueFromProps,
  items,
  defaultValue,
  getDisplayName = String,
  getItemId: getItemIdFromProps,
  multiselect,
  onRemoved,
  minQueryLength = 0,
  debounceTime = 0,
  onQuery,
  onChange: onChangeFromProps
}: ExtendedBaseAutocompleteProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { value, onChange } = useFieldValue({
    multiselect,
    value: valueFromProps,
    defaultValue,
    onChange: onChangeFromProps
  });
  const [isLoading, setIsLoading] = useState(false);

  const prevValue = usePrevious(value);

  const initValue = defaultValue ?? valueFromProps;

  const [filteredItems, setFilteredItems] = useState<T[]>(() => {
    if (items) return items;
    if (Array.isArray(initValue) || !initValue) return [];
    return [initValue];
  });

  useEffect(() => {
    if (items) {
      setFilteredItems(items);
    }
  }, [items]);

  const [query, setQuery] = useState<string>(() => {
    if (Array.isArray(initValue) || !initValue) return "";
    return getDisplayName(initValue) ?? "";
  });

  const debouncedFetchFilteredItems = useDebouncedCallback(async (query) => {
    const items = await onQuery(query);
    setFilteredItems(items ?? []);
    setIsLoading(false);
  }, debounceTime);

  const onQueryChange = useCallback(
    async (query: string) => {
      setIsOpen(true);
      setQuery(query);

      if (!multiselect && query.length === 0) {
        onChange(null);
      }
      setIsLoading(true);
      if (query.length < minQueryLength) {
        setFilteredItems([]);
      } else {
        await debouncedFetchFilteredItems(query);
      }
    },
    [
      debouncedFetchFilteredItems,
      minQueryLength,
      multiselect,
      onChange,
      setFilteredItems
    ]
  );

  const getItemId = useCallback(
    (item: T) =>
      getItemIdFromProps
        ? `option-${getItemIdFromProps(item)}`
        : `option-${stripInvalidIdCharacters(String(item))}`,
    [getItemIdFromProps]
  );

  const onSelectedValue = useCallback(
    (item: T) => {
      if (multiselect) {
        assertArray(value);
        if (isSelected(value, item, getItemId)) {
          const newItems = value.filter(
            (v) => getItemId(v) !== getItemId(item)
          );
          onChange(newItems);
        } else {
          onChange([...value, item]);
        }
        onQueryChange("");
      } else {
        onChange(item);
        onQueryChange(getDisplayName(item));
        setIsOpen(false);
      }
    },
    [getDisplayName, multiselect, onChange, onQueryChange, getItemId, value]
  );

  const { activeIndex, activeDescendant } = useKeyboardSelect(
    isOpen,
    filteredItems,
    onSelectedValue,
    setIsOpen,
    getItemId,
    inputRef.current
  );

  useEffect(() => {
    isOpen && inputRef.current?.focus();
  }, [isOpen]);

  useSetControlledValues({
    valueFromProps,
    setQuery,
    getDisplayName,
    multiselect,
    setFilteredItems
  });

  useEffect(() => {
    const areArrayValues = Array.isArray(value) && Array.isArray(prevValue);
    if (!(multiselect && areArrayValues)) return;

    const didRemoveValue = value.length < prevValue.length;
    if (didRemoveValue) {
      const removedItems = getDifference(value, prevValue, getItemId);
      onRemoved?.(removedItems);
    }
  }, [value, prevValue, multiselect, getItemId, onRemoved]);

  function onRemoveValue(item: T) {
    assertArray(value);
    const newValue = value.filter((o) => getItemId(o) !== getItemId(item));
    onChange(newValue);
  }

  return {
    isOpen,
    setIsOpen,
    inputRef,
    value,
    filteredItems,
    query,
    activeDescendant,
    activeIndex,
    onSelectedValue,
    onRemoveValue,
    onQueryChange,
    getDisplayName,
    getItemId,
    isLoading
  };
};
