import classNames from "classnames/bind";

import { Button } from "../button/button";
import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./paginator.module.scss";
const cx = classNames.bind(styles);
type PageNumberButtonProps = {
  sideindex: number;
  aktivSide: number;
  onPageClick: (pageIndex: number) => void;
  className?: string;
};

export const PageNumberButton = ({
  sideindex,
  aktivSide,
  onPageClick,
  className
}: PageNumberButtonProps) => {
  const { translation } = useUiContext();

  return (
    <li>
      <Button
        variant={sideindex === aktivSide ? "icon-filled" : "icon"}
        aria-label={translation.paginator.navigateTo.replace(
          "{0}",
          `${sideindex + 1}`
        )}
        className={cx("design-paginator-button", className)}
        onClick={() => onPageClick(sideindex)}
      >
        {sideindex + 1}
      </Button>
    </li>
  );
};
