import { RowSelectionState, Updater } from "@tanstack/react-table";
import { useState } from "react";

import { TableProps } from "../table-props";

function getRowSelectionState<T>(
  data: T[],
  selectedRows: T[],
  getRowId: (row: T) => string | number
): RowSelectionState {
  const selectionState: RowSelectionState = {};
  const dataIds = data.map((item) => getRowId(item));
  const selectedRowsIds = selectedRows.map((item) => getRowId(item));
  const selectedIndices = selectedRowsIds.map((id) => dataIds.indexOf(id));
  for (const row of selectedIndices) selectionState[row] = true;
  return selectionState;
}

function getInitSelectedValues<T>(props: TableProps<T>) {
  if (!props.isSelectable) return {};

  const { rows, selectedValues, defaultSelectedValues } = props;
  if (selectedValues) {
    return getRowSelectionState(rows, selectedValues, props.getRowId);
  }
  if (defaultSelectedValues) {
    return getRowSelectionState(rows, defaultSelectedValues, props.getRowId);
  }

  return {};
}

/**
 * A group of handlers and states regarding row seleciton.
 */
export function useRowSelection<T>(props: TableProps<T>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    getInitSelectedValues(props)
  );

  function getSelectedRowIds(
    expandFn: (old: RowSelectionState) => RowSelectionState
  ) {
    const futureExpandedState = expandFn(rowSelection);
    setRowSelection(futureExpandedState);
    return Object.keys(futureExpandedState);
  }

  function onRowSelectionChange(expandFn: Updater<RowSelectionState>) {
    if (props.isSelectable && typeof expandFn === "function") {
      const selectedRowIds = getSelectedRowIds(expandFn);

      const selectedRows = props.rows.filter((row) =>
        selectedRowIds.includes(String(props.getRowId(row)))
      );
      props.onSelectionChange?.(selectedRows);
    }
  }

  const enableRowSelection =
    props.isSelectable && (props.isRowSelectable ?? true);

  const enableMultiRowSelection = !!(props.isSelectable && props.multiselect);

  return {
    onRowSelectionChange,
    rowSelection,
    enableRowSelection,
    enableMultiRowSelection
  };
}
