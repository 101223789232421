"use client";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import classnames from "classnames/bind";
import * as React from "react";

import styles from "./date-picker.module.scss";

const cx = classnames.bind(styles);
const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

// eslint-disable-next-line react/display-name
const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cx("popover-content", className)}
      {...props}
    />
  </PopoverPrimitive.Portal>
));

export { Popover, PopoverContent, PopoverTrigger };
