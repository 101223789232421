"use client";

import { ForwardedRef, forwardRef, NamedExoticComponent } from "react";

import { AsyncAutocompleteProps } from "../autocomplete/autocomplete-props";
import BaseAutocomplete from "../autocomplete/base-autocomplete";

const DEFAULT_QUERY_LENGTH = 3;
const DEFAULT_DEBOUNCE_TIME = 500;
const AsyncAutocompleteWithoutRef = <T,>(
  props: AsyncAutocompleteProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const {
    minQueryLength = DEFAULT_QUERY_LENGTH,
    debounceTime = DEFAULT_DEBOUNCE_TIME,
    ...restProps
  } = props;

  return (
    <BaseAutocomplete
      {...restProps}
      ref={ref}
      minQueryLength={minQueryLength}
      debounceTime={debounceTime}
    />
  );
};

// Cause reacts types are lacking
export const AsyncAutocomplete = forwardRef(AsyncAutocompleteWithoutRef) as <T>(
  props: AsyncAutocompleteProps<T>
) => ReturnType<typeof AsyncAutocompleteWithoutRef>;

(AsyncAutocomplete as NamedExoticComponent).displayName = "AsyncAutocomplete";
