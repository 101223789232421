import classNames from "classnames/bind";
import {
  FieldsetHTMLAttributes,
  forwardRef,
  NamedExoticComponent
} from "react";

import { InputProps, InputWrapperProps } from "../../props/input-props";
import { ErrorMessage } from "../error-message/error-message";
import { Legend } from "../labels/legend";
import styles from "./field-set.module.scss";
const cx = classNames.bind(styles);

type HTMLTypes = FieldsetHTMLAttributes<HTMLFieldSetElement>;
type SharedInputProps = Omit<
  InputProps<"fieldset">,
  "value" | "placeholder" | "name" | "onChange" | "htmlFor"
> & {
  inline?: boolean;
};

export type FieldSetProps = SharedInputProps &
  HTMLTypes &
  Omit<InputWrapperProps, "htmlFor">;

// eslint-disable-next-line react/display-name
export const FieldSet = forwardRef<HTMLFieldSetElement, FieldSetProps>(
  (
    {
      error,
      children,
      className,
      errorId,
      label,
      description,
      required,
      disabled,
      inline,
      ...rest
    },
    ref
  ) => {
    return (
      <fieldset
        className={cx("design-fieldset", className)}
        ref={ref}
        aria-invalid={!!error}
        aria-errormessage={errorId}
        disabled={disabled}
        {...rest}
      >
        <Legend
          className={cx("design-fieldset-legend")}
          label={label}
          required={required}
          disabled={disabled}
          description={description}
        />

        <div className={cx("design-fieldset-children", { inline })}>
          {children}
        </div>

        {error && (
          <ErrorMessage className={cx("design-fieldset-error")} id={errorId}>
            {error}
          </ErrorMessage>
        )}
      </fieldset>
    );
  }
);

(FieldSet as NamedExoticComponent).displayName = "FieldSet";
