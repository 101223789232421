import classNames from "classnames/bind";
import { ComponentProps } from "react";

import styles from "./table-empty-internal.module.scss";
const cx = classNames.bind(styles);

type Props = {
  colSpan: number;
} & ComponentProps<"div">;

export function TableEmptyInternal({
  colSpan,
  children,
  className,
  ...rest
}: Props) {
  return (
    <tbody>
      <tr>
        <td colSpan={colSpan}>
          <div className={cx("table-empty", className)} {...rest}>
            {children}
          </div>
        </td>
      </tr>
    </tbody>
  );
}
