"use client";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, ListboxButton, ListboxOptions } from "@headlessui/react";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames/bind";
import { useId } from "react";

import { SelectedItems } from "../../autocomplete/selected-items/selected-items";
import { useUiContext } from "../../ui-context/use-ui-context";
import { InputWrapper } from "../input-wrapper/input-wrapper";
import { useFieldValue } from "../use-field-value";
import styles from "./select.module.scss";
import { SelectOption } from "./select-option";
import { SelectProps } from "./select-props";

const cx = classNames.bind(styles);

export function Select<T>(props: SelectProps<T>) {
  const fieldId = useId();
  const errorId = useId();
  const selectedItemsId = useId();
  const {
    onChange: onChangeFromProps,
    defaultValue: defaultValueFromProps,
    value: valueFromProps,
    children,
    label,
    name,
    placeholder,
    className,
    description,
    hideLabel,
    getItemId,
    error,
    disabled,
    required,
    getDisplayName,
    multiselect,
    onBlur,
    onFocus,
    isRemovable = false,
    fullWidth = true
  } = props;

  const { translation } = useUiContext();

  const { value, onChange } = useFieldValue({
    onChange: onChangeFromProps,
    value: valueFromProps,
    defaultValue: defaultValueFromProps,
    multiselect
  });

  const defaultValue = (() => {
    if (multiselect) return [] as T;
    if (getDisplayName) return {} as T;
    return "" as T;
  })();

  const showPlaceholder = !value || multiselect;

  const selectedItems = (() => {
    if (!value) return null;

    if (!multiselect) {
      if (getDisplayName) {
        return getDisplayName(value as T);
      }
      return String(value);
    }

    if (!Array.isArray(value)) {
      throw new TypeError(
        "Should be array if multiselect true and one or more option is selected."
      );
    }

    if (getDisplayName) {
      return value.map((option) => getDisplayName(option)).join(", ");
    }
    return value.join(", ");
  })();
  return (
    <InputWrapper
      className={className}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      description={description}
      htmlFor={fieldId}
      hideLabel={hideLabel}
      error={error}
      errorId={errorId}
      required={required}
    >
      {multiselect && Array.isArray(value) && !props.hideChips && (
        <SelectedItems
          getDisplayName={getDisplayName || String}
          getItemId={(item) =>
            getItemId ? `option-${getItemId(item)}` : `option-${String(item)}`
          }
          id={selectedItemsId}
          selectedItems={value}
          onRemove={(item) => {
            if (Array.isArray(value)) {
              onChange(value.filter((v) => v !== item));
            }
          }}
          disabled={disabled}
          orderChipsAlphabetical={props.orderChipsAlphabetical}
        />
      )}

      <Listbox
        value={value || defaultValue}
        onChange={onChange}
        name={name}
        disabled={disabled}
        multiple={multiselect}
        as={"div"}
        className={cx("full-width")}
        aria-describedby={multiselect ? selectedItemsId : ""}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {({ open }) => (
          <Popover.Root open={open}>
            <Popover.Trigger asChild>
              <ListboxButton
                id={fieldId}
                aria-label={label as string}
                aria-invalid={!!error}
                aria-errormessage={error ? errorId : undefined}
                className={cx("select-button", { error }, { open })}
              >
                <span
                  className={cx("ellipsis", "min-height", {
                    placeholder: showPlaceholder
                  })}
                >
                  {showPlaceholder ? placeholder : selectedItems}
                </span>
                <div className={cx("icon-right")}>
                  <FontAwesomeIcon
                    className={cx("caret-icon", { open })}
                    icon={faAngleDown}
                  />
                </div>
              </ListboxButton>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                align="start"
                role="presentational"
                style={{ width: "var(--radix-popover-trigger-width)" }}
                onOpenAutoFocus={(event) => event.preventDefault()}
              >
                <ListboxOptions className={cx("select-options")}>
                  {isRemovable && !multiselect && (
                    <SelectOption value={null}>
                      {placeholder ?? translation.select.removeItem}
                    </SelectOption>
                  )}
                  {children}
                </ListboxOptions>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </Listbox>
    </InputWrapper>
  );
}

/**
 * @deprecated Use SelectOption instead
 */
Select.Option = SelectOption;
