"use client";
import classnames from "classnames/bind";
import React, { useCallback, useId, useState } from "react";
import { DateValue, I18nProvider, useDatePicker } from "react-aria";
import { DatePickerStateOptions, useDatePickerState } from "react-stately";

import { InputWrapper } from "../form-elements/input-wrapper/input-wrapper";
import { InputWrapperProps } from "../props/input-props";
import { translation } from "../ui-context/ui-context";
import { useUiContext } from "../ui-context/use-ui-context";
import { Calendar } from "./calendar";
import { DateField } from "./date-field";
import styles from "./date-picker.module.scss";
import { convertToCalendarDate } from "./helpers/convert-to-calendar-date";
import { convertToDate } from "./helpers/convert-to-date";
import { mergeErrors } from "./helpers/merge-errors";
import { Popover, PopoverContent } from "./popover-content";

const cx = classnames.bind(styles);

type DatePickerProps = Omit<
  DatePickerStateOptions<DateValue>,
  "defaultValue" | "value" | "onChange"
> &
  Omit<
    InputWrapperProps,
    "errorId" | "onChange" | "htmlFor" | "defaultValue" | "hideArrows"
  > & {
    defaultValue?: Date;
    value?: Date | null;
    onChange?: (date: Date | null) => void; // Add onChange prop
  };

// eslint-disable-next-line max-statements
export function DatePicker(props: DatePickerProps) {
  const {
    value,
    defaultValue,
    label,
    description,
    hideLabel,
    className,
    required = false,
    error,
    isDisabled,
    fullWidth = false,
    onChange
  } = props;
  const context = useUiContext();

  const ref = React.useRef(null);
  const fieldId = useId();
  const errorId = useId();

  const [showPasteError, setShowPasteError] = useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const currentLanguage =
    context.translation === translation.en ? "en" : "nb-NO";

  const convertedDefaultValue = convertToCalendarDate(defaultValue);
  const convertedValue = convertToCalendarDate(value);
  const handleDateChange = useCallback(
    (newValue: DateValue | null) => {
      const newDate = convertToDate(newValue);
      if (onChange) {
        onChange(newDate);
      }
    },
    [onChange]
  );
  const state = useDatePickerState({
    ...props,
    defaultValue: convertedDefaultValue,
    value: convertedValue,
    onChange: handleDateChange
  });
  const { groupProps, fieldProps, calendarProps } = useDatePicker(
    {
      ...props,
      defaultValue: convertedDefaultValue,
      value: value ? convertedValue : undefined,
      onChange: handleDateChange
    },
    state,
    ref
  );
  const mergedError = mergeErrors(
    error,
    showPasteError,
    context.translation.datepicker.errorMessage
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter") {
      setPopoverOpen(!popoverOpen);
    }
  };

  const handleOnFocus = () => {
    if (showPasteError) {
      setShowPasteError(false);
    }
  };

  return (
    <I18nProvider locale={currentLanguage}>
      <InputWrapper
        label={label}
        fullWidth={fullWidth}
        description={description}
        disabled={isDisabled}
        htmlFor={fieldId}
        hideLabel={hideLabel}
        error={mergedError}
        errorId={errorId}
        className={className}
        required={required}
      >
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
          <div
            {...groupProps}
            ref={ref}
            className={cx("datefield-wrapper", groupProps.className)}
          >
            <DateField
              {...fieldProps}
              shouldForceLeadingZeros={true}
              label={label}
              error={error}
              errorId={errorId}
              onFocus={handleOnFocus}
              onPaste={(dateValue) => state.setDateValue(dateValue)}
              handleTogglePopover={() => setPopoverOpen(!popoverOpen)}
              handleKeyPress={handleKeyPress}
              handleSetPasteError={setShowPasteError}
            />
          </div>

          <PopoverContent className={cx("calendar")}>
            <Calendar
              calendar={calendarProps}
              closeModal={() => setPopoverOpen(false)}
            />
          </PopoverContent>
        </Popover>
      </InputWrapper>
    </I18nProvider>
  );
}
