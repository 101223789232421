"use client";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as RadixDM from "@radix-ui/react-dropdown-menu";
import classnames from "classnames/bind";
import { PropsWithChildren } from "react";

import { Button, ButtonProps } from "../button/button";
import styles from "./dropdown-menu.module.scss";
import { DropdownMenuDivider } from "./dropdown-menu-divider";
import { DropdownMenuItem } from "./dropdown-menu-item";
import { DropdownMenuSubMenu } from "./dropdown-menu-sub-menu";

const cx = classnames.bind(styles);

type Props = {
  /**
   * Label for the DropdownMenu trigger
   */
  label?: JSX.Element | string;
  /**
   * Icon for the DropdownMenu trigger.
   */
  icon?: JSX.Element;
  /**
   * Flag to remove the chevron from the DropdownMenu trigger.
   */
  noChevron?: boolean;
  className?: string;
};

export type DropdownMenuProps = Props & PropsWithChildren & ButtonProps;

export function DropdownMenu({
  children,
  label,
  icon,
  variant = "secondary",
  noChevron = false,
  className,
  disabled,
  ...props
}: DropdownMenuProps) {
  return (
    <div>
      <RadixDM.Root modal={false}>
        <RadixDM.Trigger asChild disabled={disabled}>
          <Button
            {...props}
            variant={variant}
            className={cx("dropdown-button", className)}
          >
            {icon}
            {label}
            {!noChevron && (
              <FontAwesomeIcon className={cx("chevron")} icon={faChevronDown} />
            )}
          </Button>
        </RadixDM.Trigger>

        <RadixDM.Portal>
          <RadixDM.Content alignOffset={10} className={cx("dropdown-content")}>
            {children}
          </RadixDM.Content>
        </RadixDM.Portal>
      </RadixDM.Root>
    </div>
  );
}
/**
 * @deprecated Use DropdownMenuItem instead
 */
DropdownMenu.Item = DropdownMenuItem;
/**
 * @deprecated Use DropdownMenuSubMenu instead
 */
DropdownMenu.SubMenu = DropdownMenuSubMenu;
/**
 * @deprecated Use DropdownMenuDivider instead
 */
DropdownMenu.Divider = DropdownMenuDivider;
