"use client";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarDate, createCalendar } from "@internationalized/date";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { AriaDateFieldOptions } from "@react-aria/datepicker";
import classnames from "classnames/bind";
import React from "react";
import { DateValue, useDateField, useLocale } from "react-aria";
import { useDateFieldState } from "react-stately";

import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./date-picker.module.scss";
import { convertToCalendarDate } from "./helpers/convert-to-calendar-date";
import { parseStringToDate } from "./helpers/parse-string-to-date";
import Segment from "./segment";
const cx = classnames.bind(styles);

function formatDateValueToString(date: DateValue): string {
  const year = String(date.year).padStart(4, "0");
  return `${String(date.day)}.${String(date.month).padStart(2, "0")}.${year}`;
}

interface CustomDateFieldProps extends AriaDateFieldOptions<DateValue> {
  error?: string | React.ReactNode;
  errorId: string;
  handleKeyPress: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  handleTogglePopover: () => void;
  handleSetPasteError: (isError: boolean) => void;
  onPaste: (calendarDate: CalendarDate) => void;
}

export function DateField(props: CustomDateFieldProps) {
  const {
    errorId,
    error,
    isDisabled = false,
    handleKeyPress,
    handleTogglePopover,
    handleSetPasteError,
    onPaste
  } = props;
  const { translation } = useUiContext();

  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar
  });

  const ref = React.useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedData = event.clipboardData.getData("Text");
    const parsedDate = parseStringToDate(pastedData);

    const dateValue = convertToCalendarDate(parsedDate);
    if (dateValue) onPaste(dateValue);
    else handleSetPasteError(true);
  };

  const handleCopy = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const date = state.value;
    if (!date) return;

    const formattedDate = formatDateValueToString(date);
    event.clipboardData.setData("text/plain", formattedDate);
    event.preventDefault();
  };

  return (
    <div
      {...fieldProps}
      ref={ref}
      onPaste={handlePaste}
      onCopy={handleCopy}
      className={cx("datefield", {
        error: !!error,
        disabled: isDisabled
      })}
      aria-invalid={!!error}
      aria-errormessage={error ? errorId : undefined}
    >
      {state.segments.map((segment, index) => {
        return (
          <Segment
            key={`${segment.type}-${index}`}
            segment={segment}
            state={state}
          />
        );
      })}
      <div className={cx("calendar_button_wrapper")}>
        <PopoverTrigger asChild disabled={isDisabled}>
          <button
            aria-label={translation.datepicker.openCalendar}
            onKeyDown={handleKeyPress}
            className={cx("calendar_button", {
              error: !!error,
              disabled: isDisabled
            })}
            onClick={handleTogglePopover}
          >
            <FontAwesomeIcon icon={faCalendar} className={cx("icon")} />
          </button>
        </PopoverTrigger>
      </div>
    </div>
  );
}
