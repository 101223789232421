import classNames from "classnames/bind";
import { ButtonHTMLAttributes, forwardRef, NamedExoticComponent } from "react";

import { Spinner } from "../spinner/spinner";
import styles from "./button.module.scss";

const cx = classNames.bind(styles);

type Props = {
  /** Add a loading spinner on the right side of the children */
  isLoading?: boolean;
  /** Replace children with the text when set */
  loadingText?: string;
  /** Sets the size of the button */
  size?: "small" | "medium" | "large";
  /** Only use the 'icon' and 'icon-filled' variants if children is an icon  */
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "icon"
    | "icon-filled"
    | "card";
};

export type ButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;

// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = "medium",
      variant = "primary",
      isLoading,
      loadingText,
      className,
      disabled,
      type = "button",
      children,
      ...rest
    },
    ref
  ) => (
    <button
      className={cx("design-button", className, [size, variant], {
        loading: isLoading
      })}
      disabled={disabled || isLoading}
      type={type}
      {...rest}
      ref={ref}
    >
      {variant === "card" ? (
        <>{children}</>
      ) : (
        <span className={cx("design-button-content")}>
          {!(isLoading && loadingText) && children}
          {isLoading && loadingText}
          {isLoading && (
            <Spinner
              size="small"
              variant={
                variant === "primary" || variant === "icon-filled"
                  ? "inverted"
                  : "normal"
              }
            />
          )}
        </span>
      )}
    </button>
  )
);

(Button as NamedExoticComponent).displayName = "Button";
