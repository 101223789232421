import classNames from "classnames/bind";

import { useUiContext } from "../ui-context/use-ui-context";
import styles from "./paginator.module.scss";
const cx = classNames.bind(styles);

export type Props = {
  aktivSide: number;
};

export function ActivePageIndicator({ aktivSide }: Props) {
  const { translation } = useUiContext();
  return (
    <li>
      <span
        className={cx("text")}
        aria-label={translation.paginator.currentPage.replace(
          "{0}",
          `${aktivSide + 1}`
        )}
      >
        {aktivSide + 1}
      </span>
    </li>
  );
}
