"use client";

import * as RadixPopover from "@radix-ui/react-popover";
import classnames from "classnames/bind";
import { PropsWithChildren } from "react";

import { Button, ButtonProps } from "../button/button";
import styles from "./popover.module.scss";
const cx = classnames.bind(styles);

type Props = {
  buttonText: JSX.Element | string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export type PopoverProps = Props & PropsWithChildren & ButtonProps;

export function Popover({
  children,
  buttonText,
  variant = "secondary",
  size = "medium",
  open,
  onOpenChange,
  className
}: PopoverProps) {
  return (
    <RadixPopover.Root open={open} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger asChild>
        <Button variant={variant} size={size}>
          {buttonText}
        </Button>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content className={cx("popover-content", className)}>
          {children}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
