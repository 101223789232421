/* eslint-disable @typescript-eslint/no-explicit-any */
import { flexRender, SortDirection, Table } from "@tanstack/react-table";
import classNames from "classnames/bind";

import { Sorticon } from "./sort-icon";
import styles from "./table-header.module.scss";
const cx = classNames.bind(styles);

type Props<T> = {
  table: Table<T>;
};

const DefaultColumnSize = 150;

function getPixelWidth(pixelValue: number) {
  if (pixelValue === DefaultColumnSize) return;
  return `${pixelValue}px`;
}

function getAriaSort(sort: SortDirection | false) {
  if (sort === "asc") return "ascending";
  if (sort === "desc") return "descending";
  return "none";
}

export function TableHeader<T>({ table }: Props<T>) {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} className={cx("design-table-header-row")}>
          {headerGroup.headers.map((header) => (
            <th
              className={cx("design-table-header")}
              key={header.id}
              data-test={header.id}
              aria-sort={getAriaSort(header.column.getIsSorted())}
              colSpan={header.colSpan}
              style={{ width: getPixelWidth(header.getSize()) }}
            >
              {header.column.columnDef.enableSorting === false ? (
                <>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </>
              ) : (
                <button
                  className={cx("design-table-header-sort-button", {
                    "cursor-pointer-select-none": header.column.getCanSort()
                  })}
                  type="button"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  <Sorticon sort={header.column.getIsSorted()} />
                </button>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}
