import { createContext, useContext } from "react";

import { StepperProps } from "./stepper-props";

type StepperContextProps = Pick<
  StepperProps,
  | "activeStepIndex"
  | "fadedWhenCompleted"
  | "isStepCompleted"
  | "checkmarkWhenCompleted"
>;

export const StepperContext = createContext<StepperContextProps | undefined>(
  undefined
);

export function useStepperContext() {
  const context = useContext(StepperContext);
  if (context === undefined) {
    throw new Error(
      "useStepperContext must be used within a StepperContextProvider"
    );
  }
  return context;
}
