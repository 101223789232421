import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import { Button } from "../../button/button";
import { useUiContext } from "../../ui-context/use-ui-context";
import { Row } from "../table-props";
import styles from "./table-expandable-button.module.scss";
const cx = classNames.bind(styles);

type Props<T> = {
  row: Row<T>;
};

export function TableExpandableButton<T>({ row }: Props<T>) {
  const { translation } = useUiContext();

  return (
    <Button
      size="small"
      variant="icon"
      className={cx("expandable-button")}
      data-test={`expandable-button-${row.id}`}
      aria-expanded={row.getIsExpanded()}
      aria-controls={`row-expandable-${row.id}`}
      aria-label={translation.table.expandRow}
      onClick={() => row.toggleExpanded()}
    >
      <FontAwesomeIcon
        fixedWidth
        className={cx("expand-icon", { expanded: row.getIsExpanded() })}
        icon={faAngleDown}
      />
    </Button>
  );
}
