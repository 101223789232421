import classNames from "classnames/bind";
import { ComponentProps, ReactNode } from "react";

import { ErrorMessage } from "../form-elements/error-message/error-message";
import styles from "./action-buttons.module.scss";

const cx = classNames.bind(styles);

type Props = {
  /**
   * Any error you might want to show to the user
   */
  error?: ReactNode;
} & ComponentProps<"div">;

export function ActionButtons({ error, children, className, ...rest }: Props) {
  return (
    <section className={cx(className, "action-buttons-container")}>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div className={cx(className, "buttons")} {...rest}>
        {children}
      </div>
    </section>
  );
}
