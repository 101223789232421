import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Portal,
  Sub,
  SubContent,
  SubTrigger
} from "@radix-ui/react-dropdown-menu";
import classnames from "classnames/bind";
import { PropsWithChildren } from "react";

import styles from "./dropdown-menu.module.scss";

const cx = classnames.bind(styles);

export type DropdownMenuSubMenuProps = {
  className?: string;
  /**
   *  Flag to deactivate the submenu
   */
  disabled?: boolean;
  label?: string;
} & PropsWithChildren;

export function DropdownMenuSubMenu({
  children,
  className,
  label,
  disabled
}: DropdownMenuSubMenuProps) {
  return (
    <Sub>
      <SubTrigger
        disabled={disabled}
        className={cx("dropdown-subtrigger", className)}
      >
        {label}
        <FontAwesomeIcon
          className={cx("dropdown-subtrigger-chevron")}
          icon={faChevronRight}
        />
      </SubTrigger>

      <Portal>
        <SubContent className={cx("dropdown-subcontent", className)}>
          {children}
        </SubContent>
      </Portal>
    </Sub>
  );
}
