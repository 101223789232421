"use client";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GregorianCalendar } from "@internationalized/date";
import classnames from "classnames/bind";
import {
  AriaCalendarProps,
  DateValue,
  useCalendar,
  useLocale
} from "react-aria";
import { useCalendarState } from "react-stately";

import { useUiContext } from "../ui-context/use-ui-context";
import { CalendarGrid } from "./calendar-grid";
import styles from "./date-picker.module.scss";
import { MonthDropdown } from "./month-dropdown-list";
import { YearDropdown } from "./year-dropdown-list";
const cx = classnames.bind(styles);

type Props = {
  calendar: AriaCalendarProps<DateValue>;
  closeModal: () => void;
};
export function Calendar({ calendar, closeModal }: Props) {
  const { translation } = useUiContext();

  const { locale } = useLocale();
  const state = useCalendarState({
    ...calendar,
    locale,
    createCalendar: () => new GregorianCalendar()
  });

  const { calendarProps } = useCalendar(calendar, state);

  return (
    <div {...calendarProps}>
      <div className={cx("header")}>
        <button
          onClick={state.focusPreviousPage}
          aria-label={translation.datepicker.previousMonth}
          className={cx("month_buttons")}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <MonthDropdown state={state} />
        <YearDropdown state={state} />

        <button
          onClick={state.focusNextPage}
          aria-label={translation.datepicker.nextMonth}
          className={cx("month_buttons")}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <CalendarGrid state={state} closeModal={closeModal} />
    </div>
  );
}
