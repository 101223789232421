"use client";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { ElementType } from "react";

import styles from "./stepper.module.scss";
import { useStepperContext } from "./stepper-context";
import { StepperStepProps } from "./stepper-props";

const cx = classNames.bind(styles);

function assertStepIndex(stepIndex?: number): asserts stepIndex is number {
  if (stepIndex === undefined)
    throw new Error(
      "The Step index was `undefined`. This is likely a bug within the Stepper-component itself. Please open a bug-ticket."
    );
}

export function StepperStep<C extends ElementType = "div">(
  props: StepperStepProps<C>
) {
  const { as, children, label, className, stepIndex, ...rest } = props;

  const {
    activeStepIndex,
    fadedWhenCompleted,
    isStepCompleted = (currentStepIndex) => stepIndex < currentStepIndex,
    checkmarkWhenCompleted
  } = useStepperContext();

  assertStepIndex(stepIndex);

  const Component = as ?? "div";
  const content = children || stepIndex + 1;

  const active = stepIndex === activeStepIndex;
  const completed = isStepCompleted(stepIndex);
  const faded = fadedWhenCompleted && completed;

  const displayCheckmark = completed && checkmarkWhenCompleted;

  return (
    <div
      className={cx("step", { completed, active, faded }, className)}
      title={label}
    >
      <Component className={cx("step-content")} {...rest}>
        {displayCheckmark ? (
          <FontAwesomeIcon icon={faCheck} fixedWidth size="sm" />
        ) : (
          content
        )}
        {label && <p className={cx("step-description")}>{label}</p>}
      </Component>
    </div>
  );
}
