"use client";

import { ForwardedRef, forwardRef, NamedExoticComponent } from "react";

import { AutocompleteProps } from "./autocomplete-props";
import BaseAutocomplete from "./base-autocomplete";

const AutocompleteWithoutRef = <T,>(
  props: AutocompleteProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const WHITESPACE_REGEX = /\s+/g;

  const { items, getDisplayName = String } = props;

  function getFilteredItems(query: string): T[] {
    if (query === "") return items;

    return items.filter((item) => {
      const searchName = getDisplayName(item)
        .toLowerCase()
        .replaceAll(WHITESPACE_REGEX, "");
      const searchQuery = query.toLowerCase().replaceAll(WHITESPACE_REGEX, "");
      return searchName?.includes(searchQuery);
    });
  }

  return (
    <BaseAutocomplete
      {...props}
      onQuery={(query) => Promise.resolve(getFilteredItems(query))}
      ref={ref}
    />
  );
};

// Cause reacts types are lacking
export const Autocomplete = forwardRef(AutocompleteWithoutRef) as <T>(
  props: AutocompleteProps<T>
) => ReturnType<typeof AutocompleteWithoutRef>;

(Autocomplete as NamedExoticComponent).displayName = "Autocomplete";
