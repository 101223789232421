import classNames from "classnames/bind";
import { ComponentProps, ElementType } from "react";

import styles from "./link.module.scss";

const cx = classNames.bind(styles);

export type LinkProps<C extends ElementType> = {
  /**
   * The element to render
   * @default "a"
   */
  as?: C;
  /**
   * Whether standard, card or button variants should be used
   * @default "normal"
   */
  variant?:
    | "normal"
    | "inverted"
    | "card"
    | "button-primary"
    | "button-secondary";
  /**
   * Relevant for button variants only. Whether small, medium or large size should be used
   * @default "medium"
   */
  size?: "small" | "medium" | "large";
} & ComponentProps<C>;

export function Link<C extends ElementType = "a">({
  as,
  className,
  children,
  variant = "normal",
  size = "medium",
  ...rest
}: LinkProps<C>) {
  const Component = as || "a";

  return (
    <Component className={cx("link", className, variant, size)} {...rest}>
      {children}
    </Component>
  );
}
