import classNames from "classnames/bind";

import { LabelProps } from "../../props/input-props";
import styles from "./labels.module.scss";
const cx = classNames.bind(styles);

export type LegendProps = Omit<LabelProps, "htmlFor">;

export function Legend({
  label,
  className,
  description,
  disabled,
  required
}: LegendProps) {
  return (
    <legend className={cx("design-label", className, { disabled })}>
      <span>
        {label}
        {required && <span className={cx({ required })}> *</span>}
      </span>
      {description && (
        <span className={cx("design-label-description")}>{description}</span>
      )}
    </legend>
  );
}
