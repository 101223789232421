import { TableProps } from "../table-props";

export function getInitItemsPerPage<T>({
  hideFooter,
  rows,
  itemsPerPage
}: TableProps<T>) {
  const defaultItemsPerPage = 15;
  if (!hideFooter) return itemsPerPage ?? defaultItemsPerPage;

  return rows.length;
}
